<template>
  <div class="row">
    <div class="col-md-12" v-if="loading">
      <div class="row mt-5 pt-5">
        <div class="col-md-12" v-loading="loading"></div>
      </div>
    </div>
    <div class="col-md-12 my-4" v-if="!loading">
      <div class="d-flex justify-content-end">
        <div class="form_contain">
          <el-select
            size="small"
            placeholder="Select country"
            v-model="selected_country"
            v-if="userCountry == 'GH'"
            @change="handleChangeCountry"
          >
            <el-option
              label="Ghana"
              value="GH"
              v-if="userCountry == 'GH'"
            ></el-option>
            <el-option label="CIV" value="CI"></el-option>
          </el-select>
          <el-input
            placeholder="search by name or amount"
            class="search_item"
            style="height: 40px !important"
            v-model="search"
          >
            <i
              slot="prefix"
              style="padding-to: 2em"
              class="el-input__icon el-icon-search"
            ></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="!loading">
      <el-table
        :data="displayData"
        :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
        style="width: 100%"
        :empty-text="'No Data Available'"
        height="55vh"
        v-loading="dataChanging"
      >
        <el-table-column label="Name" prop="name" width="name">
        </el-table-column>
        <el-table-column label="Amount" width="amount" prop="amount">
        </el-table-column>
        <el-table-column label="Payment Date">
          <template slot-scope="scope">
            {{ moment(scope.row.payment_date).format("L") }}
          </template>
        </el-table-column>
        <el-table-column
          label="Balance Before Deposit"
          prop="balance_before_deposit"
          width=""
        >
        </el-table-column>
        <!-- <el-table-column
                label="Action"
                width="200">
                <template slot-scope="scope">
                    <button id="enter__account" class="approve" @click="publish(scope)" >
                        Publish
                    </button>
                </template>
            </el-table-column> -->
      </el-table>
    </div>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import appConfig from "@/app.config";
  import Storage from "../../../services/storage";
  var storage = new Storage();

  export default {
    page: {
      title: "Wallet history",
      meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
      return {
        loading: false,
        tableData: [],
        page: 1,
        pageSize: 20,
        total: 0,
        search: "",
        selected_country: "GH",
        dataChanging: false,
      };
    },
    computed: {
      searchingTableData() {
        if (!this.search) {
          this.total = this.tableData.length;
          return this.tableData;
        }
        this.page = 1;
        return this.tableData.filter(
          (data) =>
            data.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.amount == this.search
        );
      },
      getCurrency() {
        return this.selected_country === "CI" ? "FCFA" : "GHC";
      },
      userCountry() {
        return storage.getSavedState("auth.admin").country;
      },
      displayData() {
        this.total = this.searchingTableData.length;
        return this.searchingTableData.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
    },
    mounted() {
      this.loading = true;
      store
        .dispatch("admin/reporting/getWalletHistory")
        .then((res) => (this.tableData = res))
        .finally(() => (this.loading = false));
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      handleChangeCountry() {
        this.dataChanging = true;
        store
          .dispatch("admin/reporting/getWalletHistory", {
            country: this.selected_country,
          })
          .then((res) => (this.tableData = res))
          .finally(() => (this.dataChanging = false));
      },
    },
  };
</script>

<style scoped>
  .form_contain {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
</style>
